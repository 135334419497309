import React from "react";
import Card from "./card";
import './PostSection.css'

const Articles = ({ articles }) => {
  const leftArticlesCount = Math.ceil(articles.length / 5);
  const leftArticles = articles.slice(0, leftArticlesCount);
  const rightArticles = articles.slice(leftArticlesCount, articles.length);

  return (

    <div>
    <div className="PostSection">
      <h2 className="PostSection--Title"></h2>
        <div className="PostSection--Grid-3 space-50t">
            {articles.map((article, i) => {
                return (
              <Card article={article} key={`article__left__${article.node.slug}`}/>
            );
        })}
        </div>
    </div>
      
    </div>
  );
};

export default Articles;
