import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/Layout";
import ArticlesComponent from "../components/articles";
import PageHeader from '../components/PageHeader-Secondary'

const IndexPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout seo={data.strapiHomepage.seo}>
    <PageHeader
      /*title={title}*/
      title="Blogg"
      subtitle="På denne bloggen vil jeg publisere innlegg om enkle baketips og jeg vil dele min lidenskap for baking."
      backgroundImage=""
    />

      <section className="section row thin">
        <div className="container space-20t space-100b newstabs">
          <ArticlesComponent articles={data.allStrapiArticle.edges} />
        </div>
      </section>

    </Layout>
  );
};

const query = graphql`
  query {
    strapiHomepage {
      hero {
        title
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          publicURL
        }
      }
    }
    allStrapiArticle(filter: { status: { eq: "published" } }) {
      edges {
        node {
          strapiId
          slug
          title
          description
          image {
            childImageSharp {
              fixed(width: 800, height: 500) {
                src
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
