import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import './PostCard.css'

const Card = ({ article }) => {
  return (
    <Link to={`/blogg/${article.node.slug}`} className="uk-link-reset">
      <div className="uk-card uk-card-muted">
        <div className="uk-card-media-top">
          <Img
            fixed={article.node.image.childImageSharp.fixed}
            imgStyle={{ position: "static", borderRadius: "15%" }}
          />
        </div>
        <div className="PostCard--Content">
        <h3 id="title" className="PostCard--Title">
          {article.node.title}
        </h3>
          <p id="category" className="PostCard--Excerpt">
            {article.node.description}
          </p>

          <div>

          </div>
        </div>
      </div>
    </Link>
  );
};

export default Card;
