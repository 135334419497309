import React, { useEffect, createRef } from 'react'
import PropTypes from 'prop-types'

import Image from './Image'
import Content from './Content'
import './PageHeader.css'





const PageHeader = ({
  fronttitle,
  title,
  subtitle,
  backgroundImage,
  large,
  className = ''
}) => (
    <div class="PageHeader-Secondary">
        <div class="container">
        <div class="PageHeader--Left">
        <h1 className="PageHeader--FrontTitle--Secondary">{title}</h1>
        <p className="PageHeader--Subtitle--Secondary">{subtitle}</p>
        </div>
        <div class="PageHeader--Right">
        </div>
      </div>


    </div>
)




PageHeader.propTypes = {
  fronttitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default PageHeader
